/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { RpcStatus, V1UpdateResponse } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class B2BCompanies<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags B2BCompanyService
     * @name B2BCompanyServiceUpdate
     * @request PATCH:/b2b_companies/{id}
     * @secure
     */
    b2BCompanyServiceUpdate = (
        id: string,
        body: {
            /** @pattern ^[-\p{L}\p{N}](?: ?[-\p{L}\p{N}]){0,253}[-\p{L}\p{N}]{0,1}$ */
            title?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1UpdateResponse, RpcStatus>({
            path: `/b2b_companies/${id}`,
            method: "PATCH",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
}
